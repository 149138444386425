<template>
  <div class="buy-header">

    <div class="logo"></div>

    <div>
      <h1><span><img :src="icons.coin" alt="Coin"/></span>{{ quantity }} Coins</h1>
      <h2>{{ product.price | currency( product.currency )}}</h2>
    </div>

  </div>
</template>

<script>
import Filters from '@/utils/filters'

export default {
  name: 'ShopBuyHeader',

  props: {
    product: Object
  },

  data() {
    return {
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  filters: {
    ...Filters
  },

  computed: {
    quantity() {
      const coins = this.product.quantity
      return coins >= 100000 ? this.$Utils.Filters.abbriviate( coins ) : coins.toLocaleString()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.buy-header {

  .logo {
    width: 48px;
    height: 48px;
    background-image: url( '~@/assets/images/logo_circle.png' );
    @include image-center;
    margin: 0 auto;
    margin-bottom: 8px;
  }

  h1 {
    color: $off-black;
    font-size: 22px;
    @include flex-center;

    span {
      padding: 0 6px 0 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  h2 {
    color: $med-dark-grey;
    font-size: 14px;
  }
}
</style>

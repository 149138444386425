<template>
  <div class="stripe">

    <h2>Stripe</h2>

    <div ref="card" class="card"></div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ShopBuyStripe',

  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      apiLoading: false,
      cardLoaded: false,
      secret: null,
      response: null,
      error: false
    }
  },

  props: {
    product: Object,
    close: Function
  },

  computed: {
    loading() {
      return this.apiLoading || !this.cardLoaded
    }
  },

  methods: {
    ...mapActions( 'Shop', {
      request: 'stripe'
    }),

    async purchase( e ) {
      //TODO do we need this when we can do .prevent on the button?
      if ( e ) e.preventDefault()

      this.apiLoading = true
      const payment = await this.stripe.confirmCardPayment( this.secret, {
        payment_method: {
          card: this.card
        }
      })

      this.response = ( payment.error ) ? payment.error.message : 'Payment Successful'
      this.error = !!payment.error
      this.apiLoading = false
    },

    reset() {
      this.response = null
      this.error = false
    }
  },

  async mounted() {
    this.stripe = window.Stripe( process.env.VUE_APP_STRIPE_PK )
    this.elements = this.stripe.elements()

    //Build UI
    this.card = this.elements.create( 'card', { hidePostalCode: true })
    this.card.mount( this.$refs.card )
    this.card.on( 'ready', () => this.cardLoaded = true )

    //Get client secret
    this.secret = await this.request( this.product.id ).catch( e => this.error = e )
    this.apiLoading = false
  },

  beforeDestroy() {
    if ( this.card ) this.card.destroy( this.$refs.card )
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.card {
  margin-top: 16px;
}

.carl-el {
  margin-bottom: 5px;
}
</style>

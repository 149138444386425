<template>
  <div class="shop page">

    <h2 class="bk">Balance:<span><img :src="icons.coin" alt="Coin"/></span>{{ balance }}</h2>

    <div class="products" v-if="products">
      <!-- <Advertisement :quantity="100"/> -->

      <Product :product="small"/>
      <Product :product="medium"/>
      <Product :product="large"/>
      <Product :product="huge"/>
      <Product :product="chest"/>

      <!-- <Subscription :product="subscription"/> -->
    </div>

  </div>
</template>

<script>
// import Advertisement from '@/components/shop/products/Advertisement.vue'
import Product from '@/components/shop/products/Coins.vue'
// import Subscription from '@/components/shop/products/Subscription.vue'

import Filters from '@/utils/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'Shop',

  data() {
    return {
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  filters: {
    ...Filters
  },

  components: {
    // Advertisement,
    Product,
    // Subscription
  },

  computed: {
    ...mapGetters( 'Shop', {
      products: 'products'
    }),

    ...mapGetters( 'Auth', {
      balance_: 'balance'
    }),

    small() {
      return { ...this.products[ 0 ], image: require( '@/assets/images/shop/small.png' )}
    },

    medium() {
      return { ...this.products[ 1 ], image: require( '@/assets/images/shop/medium.png' )}
    },

    large() {
      return { ...this.products[ 2 ], image: require( '@/assets/images/shop/large.png' )}
    },

    huge() {
      return { ...this.products[ 3 ], image: require( '@/assets/images/shop/huge.png' )}
    },

    chest() {
      return { ...this.products[ 4 ], image: require( '@/assets/images/shop/chest.png' )}
    },

    balance() {
      const coins = this.balance_
      return coins >= 100000 ? this.$Utils.Filters.abbriviate( coins ) : coins.toLocaleString()
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.shop {
  padding: $page-padding;
  text-align: right;

  h2 {
    @include flex-config( true, flex-end, center );
    margin-left: auto;

    span {
      padding: 0 6px 0 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .products {
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
    grid-gap: $page-padding;
    padding-top: $page-padding;

    @media ( max-width: $tablet ) {
      grid-template-columns: repeat( 2, 1fr );
    }

    @media ( max-width: $mobile ) {
      grid-template-columns: repeat( 1, 1fr );
    }
  }
}
</style>

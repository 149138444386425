<template>
  <div class="modal-container bk" @mousedown.stop="close">
    <form class="shop-buy modal" @mousedown.stop @submit.prevent="submit" :class="provider">

      <Header :product="product"/>
      <Providers v-if="!provider" :select="p => { provider = p }"/>

      <Stripe v-if="stripe" :product="product" :close="close"/>
      <Paypal v-if="paypal" :product="product" :close="close"/>

    </form>
  </div>
</template>

<script>
import Header from '@/components/shop/Header.vue'
import Providers from '@/components/shop/providers/Container.vue'
import Stripe from '@/components/shop/providers/Stripe.vue'
import Paypal from '@/components/shop/providers/Paypal.vue'

export default {
  name: 'ShopBuy',

  data() {
    return {
      provider: null
    }
  },

  props: {
    close: Function,
    product: Object
  },

  components: {
    Header,
    Providers,
    Stripe,
    Paypal
  },

  computed: {
    stripe() {
      return this.provider && this.provider.toLowerCase() === 'stripe'
    },

    paypal() {
      return this.provider && this.provider.toLowerCase() === 'paypal'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.shop-buy {
  min-height: 80px;

  h1 {
    color: $off-black;
    font-family: $font;
    font-size: 16px;
  }

  //Special rules for different providers
  &.paypal {
    padding-bottom: 14px;
    overflow-y: scroll;
  }
}
</style>

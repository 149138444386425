<template>
  <div class="paypal">

    <div v-show="!loading" id="paypal-button-container"></div>

    <Loading v-if="loading"/>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ShopBuyPaypal',

  data() {
    return {
      loading: true
    }
  },

  props: {
    product: Object,
    close: Function
  },

  components: {
    Loading
  },

  methods: {
    ...mapActions( 'Shop', {
      request: 'paypal'
    }),

    async create( data, actions ) {
      this.loading = true

      const id = await actions.order.create({
        purchase_units: [{
          amount: {
            currency_code: this.product.currency,
            value: this.product.price.toString()
          },
          description: this.product.description
        }]
      })
      
      await this.request({ id, item: this.product.id })

      this.loading = false
      return id
    },

    async approved( data, actions ) {
      const order = await actions.order.capture()
      this.close()
      return order
    }
  },
  
  async mounted() {
    const script = document.createElement( 'script' )
    const url = `https://www.paypal.com/sdk/js?currency=GBP&client-id=${ process.env.VUE_APP_PAYPAL_CLIENT_ID }`
    script.setAttribute( 'src', url )
    script.async = true

    script.onload = () => {
      this.loading = false
      window.paypal.Buttons({ createOrder: this.create, onApprove: this.approved }).render( '#paypal-button-container' )
    }

    document.head.appendChild( script )
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.paypal {
  position: relative;

  //Loading spinner
  .spinner-container {
    padding: 18px 0 22px 0;
  }
}
</style>
<template>
  <div class="providers">

    <!-- <span class="stripe" @click.stop="select( 'stripe' )" title="Stripe">
      <img :src="icons.stripe" title="Stripe" alt="Stripe"/>
      <p>Stripe</p>
    </span> -->

    <span class="paypal" @click.stop="select( 'paypal' )" title="Paypal">
      <img :src="icons.paypal" title="Paypal" alt="Paypal"/>
      <p>Paypal</p>
    </span>

  </div>
</template>

<script>
export default {
  name: 'ShopBuyProviders',

  data() {
    return {
      icons: {
        stripe: require( '@/assets/icons/shop/card.svg' ),
        paypal: require( '@/assets/icons/shop/card.svg' )
      }
    }
  },

  props: {
    select: Function
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.providers {
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: repeat( 1, 1fr );

  span {
    @include box-shadow( $shadow );
    padding: 16px;
    cursor: pointer;
    text-align: center;
    background-color: $off-white;

    &:hover {
      background-color: $white;
    }

    img {
      width: 24px;
      height: 24px;
    }

    p {
      text-align: center;
      padding-top: 12px;
    }
  }
}
</style>

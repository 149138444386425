<template>
  <div class="shop-product" @click.stop="modal = true" :title="product.description">

    <div class="image" :style="preview"></div>

    <h3><span><img :src="icons.coin" alt="Coin"/></span>{{ quantity }} - {{ product.price | currency( product.currency )}}</h3>

    <Buy v-if="modal" :close="() => modal = false" :product="product"/>

  </div>
</template>

<script>
import Buy from '@/components/shop/Buy.vue'
import Filters from '@/utils/filters'

export default {
  name: 'ShopProductCoins',

  props: {
    product: Object
  },

  data() {
    return {
      modal: false,
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  filters: {
    ...Filters
  },

  components: {
    Buy
  },

  computed: {
    quantity() {
      const coins = this.product.quantity
      return coins >= 100000 ? this.$Utils.Filters.abbriviate( coins ) : coins.toLocaleString()
    },

    preview() {
      return `background-image: url(${ this.product.image });`
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.shop-product {
  background: $gradient-white;
  border-radius: 4px;
  @include box-shadow( $shadow );
  padding: 24px;
  cursor: pointer;

  &:hover {
    background-color: $white;
  }

  .image {
    border-radius: 2px;
    padding-top: clamp( 60px, 50%, 130px );
    @include image-center;
    background-size: contain;
  }

  h3 {
    font-size: 16px;
    padding-top: 24px;
    @include flex-center;
    margin-left: auto;

    span {
      padding: 0 6px 0 8px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
